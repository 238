import React from 'react';
import {Card, CardBody, Typography} from "@material-tailwind/react";
import useGetData from "../../../utils/useGetData";
import {useNavigate} from "react-router-dom";

export const PopularCategoryCards = () => {
    const data = useGetData('/categories-count');
    const navigate = useNavigate();

    const handleClick = (category) => {
        navigate('/ogloszenia', { state: { filter: {key: 'category', value: category} } });
    };

    return (
        <div className='w-full mt-7'>

            <Typography variant='h4' className='ml-7 mt-2'>Popularne Kategorie</Typography>
            <hr className='my-3'/>

            <div className="flex gap-6 px-8 flex-wrap">
                {data && data.map((c, index) => (
                    <Card key={index} className="bg-[#fefefe] shadow-md hover:-translate-y-1 hover:border-blue-gray-100/60 transition-all hover:cursor-pointer" onClick={() => handleClick(c.category_name)}>
                        <CardBody>
                            <Typography variant="h5" className="text-xl font-bold">
                                {c.category_name}
                            </Typography>
                            <Typography variant="body1">
                                Liczba: {c.count}
                            </Typography>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default PopularCategoryCards;
