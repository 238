import {Button, Card, CardBody, CardFooter, Typography} from "@material-tailwind/react";
import useGetData from "../../../utils/useGetData";
import {useNavigate} from "react-router-dom";

export default function HomePopularCities() {

    const navigate = useNavigate();

    const handleClick = (city) => {
        navigate('/ogloszenia', { state: { filter: {key: 'city', value: city} } });
    };


    const data = useGetData('/cities-count')

    return (
        <div className='pt-4 mt-7'>
            <Typography variant={'h4'} className='ml-7 mt-2'>
                Najpopularniejsze miasta
            </Typography>
            <hr className="my-3"/>

            <div className="flex gap-6 px-8 flex-wrap">
                {data && data.map((c, index) => (
                    <Card key={index} onClick={() => handleClick(c.city)}
                          className="bg-[#fefefe] shadow-md hover:-translate-y-1 hover:border-blue-gray-100/60 transition-all hover:cursor-pointer">
                        <CardBody>
                            <Typography variant="h5" className="text-xl font-bold">
                                {c.city}
                            </Typography>
                            <Typography variant="body1">
                                Liczba: {c.count}
                            </Typography>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    )
}