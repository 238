import { Typography } from "@material-tailwind/react";

function FooterLink({href, name}) {
    return (
        <li>
            <Typography
                as="a"
                href={href}
                color="blue-gray"
                className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
            >
                {name}
            </Typography>
        </li>
    )
}

export function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer
            className="flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 border-t border-blue-gray-50 py-6 md:justify-between mt-2">
            <Typography color="blue-gray" className="font-normal pl-2">
                &copy; {currentYear} Zdaj-to.pl
            </Typography>
            <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">

                <FooterLink href={'/ogloszenia'} name='Ogłoszenia Korepetytorów' />

                <FooterLink href={'/ogloszenia/u'} name='Ogłoszenia uczniów' />

                <FooterLink href={'/o-serwisie'} name='O serwisie' />

                <FooterLink href={process.env.PUBLIC_URL + '/regulamin.pdf'} name='Regulamin' />

                <FooterLink href={'/zgloszenie'} name='Zgłoś błąd' />

                <FooterLink href={'/zgloszenie'} name='Propozycja rozwoju' />
            </ul>
        </footer>
    );
}