import Home from "./pages/Home";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";
import SignIn from "./pages/SignIn"
import Announcements from "./pages/Announcements";
import About from "./pages/About";
import AddAnnouncementTutor from "./pages/AddAnnouncementTutor";
import SingUp from "./pages/SingUp";
import React from "react";
import TutorAnnouncement from "./pages/TutorAnnouncement";
import Test from "./pages/Test";
import ProtectedRoutes from "./components/ProtectedRoutes";
import Profile from "./pages/Profile";
import AddAnnouncementStudent from "./pages/AddAnnouncementStudent";
import Logout from "./pages/Logout";
import StudentAnnouncement from "./pages/StudentAnnouncement";
import Regulations from "./pages/Regulations";
import StudentAnnouncements from "./pages/StudentAnnouncements";
import EditAnnouncementTutor from "./pages/EditAnnouncementTutor";
import EditAnnouncementStudent from "./pages/EditAnnouncementStudent";
import Messages from "./pages/Messages";
import WithSuspense from "./components/WithSuspense";
import ActivationAccount from "./pages/ActivationAccount";
import ResetPassword from "./pages/ResetPassword";
import ReportForm from "./pages/ReportForm";
import Notifications from "./pages/Notifications";
import NotFound from "./pages/NotFound";

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<Home/>}/>
                    <Route path="/logowanie" element={<SignIn/>}/>
                    <Route path="/rejestracja" element={<SingUp/>}/>
                    <Route path="/ogloszenia" element={<Announcements/>}></Route>
                    <Route path="/ogloszenia/u" element={<StudentAnnouncements/>}></Route>
                    <Route path="/o-serwisie" element={<About/>}></Route>
                    <Route path="/ogloszenie/:id" element={<TutorAnnouncement/>}></Route>
                    <Route path="/ogloszenie/u/:id" element={<StudentAnnouncement/>}></Route>
                    <Route path='/profil/:id' element={<Profile/>}></Route>
                    <Route path='/aktywuj' element={<ActivationAccount/>} />
                    <Route path='/resetuj-haslo' element={<ResetPassword/>} />
                    <Route path='/zgloszenie' element={<ReportForm/>} />
                    <Route path="/test" element={<Test/>}/>

                    {/*protected*/}
                    <Route element={<ProtectedRoutes/>}>
                        <Route path="/dodaj-korepetytor" element={<AddAnnouncementTutor/>}></Route>
                        <Route path="/powiadomienia" element={<Notifications/>}></Route>
                        <Route path="/dodaj-uczen" element={<AddAnnouncementStudent/>}></Route>
                        <Route path="/edytuj/:id" element={<EditAnnouncementTutor />}></Route>
                        <Route path="/edytuj/u/:id" element={<EditAnnouncementStudent />}></Route>
                        <Route path="/konwersacje/:id" element={<WithSuspense children={<Messages/>} />}></Route>
                        <Route path="/konwersacje" element={<WithSuspense children={<Messages/>} />}></Route>
                        <Route path="/wyloguj" element={<Logout/>}></Route>
                    </Route>

                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}
