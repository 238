import {Button} from "@material-tailwind/react";

export default function NotFound() {

    return (
        <div className='flex flex-col w-full h-80vh justify-center items-center'>
            <p className='text-blue-gray-700 text-6xl'>404</p>
            <p>Strona nie została znaleziona</p>
            <div className='flex mt-3 space-x-3'>
                <a href='/'><Button>Strona główna</Button></a>
                <a href='/ogloszenia'><Button variant='outlined'>Ogłoszenia</Button></a>
            </div>
        </div>
    )

}